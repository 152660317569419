import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import config from "../../config/config"

const BlogItems = ({parentURL}) => {
    const allItemsRaw = useStaticQuery(graphql`
    {
        allSilverStripeDataObject(filter: {className: {eq: "BlogPost"}}, sort: {fields: BlogPost___date, order: DESC}) {
            nodes {
                BlogPost {
                    customTitle
                    summary
                    date(formatString: "Do MMMM YYYY")
                    elementalAreaID
                    categoryID
                    tileBlackTitle
                    imagePath
                    Category {  
                        UndigitalBlogCategory {
                          name
                        }
                    }
                    Parent {
                        link
                        SilverStripeSiteTree {
                            urlSegment
                          }
                    }
                }
                SilverStripeSiteTree {
                    title
                }
                link
            }
        }
    }
  `);
    const allItemsNodes = allItemsRaw.allSilverStripeDataObject.nodes
    var _allData = [];
    const [pageLimit, setPageLimit] = useState(8)
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    var pageNum = 0
    var keyword = null;
    if(params.get("page")){
        pageNum = parseInt(params.get("page"))
    }
    if(params.get("keyword")){
        keyword = params.get("keyword").toLowerCase()
    }
    const nl2br = text =>
        text
    .split(/(?:\r\n|\r|\n)/)
    .reduce(
      (res, frag, i, arr) => [
        ...res,
        frag,
        ...(i < arr.length - 1 && [React.createElement("br")])
      ],
      []
    );
    
    var host = config.host
    var i = 0
    var blogHolderLink = null
    for (; allItemsNodes[i]; ) {
        var node = allItemsNodes[i]
        let _post = node.BlogPost
        let parentURLSegment = _post.Parent.SilverStripeSiteTree.urlSegment
        if(parentURLSegment !== parentURL) continue
        if(blogHolderLink === null){
            blogHolderLink = _post.Parent.link
        }
        _post.title = node.SilverStripeSiteTree.title
        if(_post.imagePath){
            _post.image = _post.imagePath
        }
        _post.link = node.link
        if(_post.Category){
            _post.category = _post.Category.UndigitalBlogCategory.name
        }
        if(_post.customTitle !== ''){
            _post.title = _post.customTitle.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }
        let match = true
        if (keyword) {
            let postTitle = _post.title.toLowerCase()
            let matched_title = postTitle.search(keyword)
            let postCategory = _post.category.toLowerCase()
            let matched_category = postCategory.search(keyword)
            if (matched_title === -1 && matched_category === -1) {
                match = false
            }
          }
        if (match) {
            _allData.push(_post)
        }
        i++;
    }
    const _total_items = _allData.length
    const _total_pages = Math.ceil(_total_items / pageLimit)
    var prevLink, nextLink = null
    
    if (pageNum > _total_pages) {
        pageNum = _total_pages
    }    
    var offset = 0
    var endOffset = pageLimit
    if(pageNum >= 1){
        offset = (pageNum - 1) * pageLimit
        endOffset = pageNum * pageLimit
    }
    var currentData = _allData.slice(offset, endOffset)
    var pages = []
    
    if(_total_pages > 1){
        for (var j=1; j<=_total_pages; j++) {
            let page = {}
            page.pageNumber = j
            page.link = blogHolderLink + '?page=' + j
            pages.push(page)            
        }        
        if(pageNum > 1){
            prevLink = blogHolderLink + '?page=' + (pageNum - 1)
        }
        if(pageNum < _total_pages){
            nextLink = blogHolderLink + '?page=' + (pageNum + 1)
        }
    }
    
  return (
        <div className="container my-4">
            <div className="row gutters-10">
                {currentData.map((post, idx) => (
                    <div key={`post-${idx}`} className="col-sm-6 col-lg-3">
                      <div className="grid-item">
                        <div className="grid-item-inner">
                          <img src={post.image} alt={post.title} />
                          <h4 className={`grid-title${post.tileBlackTitle == 1 ? ' text-black' : ''}`} dangerouslySetInnerHTML={{ __html: post.title }}/>
                          <a href={post.link} className="grid-caption related-articles-caption">
                            <div className="inner">
                              <div className="related-articles-category">{post.category}</div>
                              <div className="related-articles-date">
                                {post.date}
                              </div>
                            </div>
                          </a>
                          {/*
                          <button type="button" className="btn-like">
                            <svg width="30px" height="30px">
                              <title>Like</title>
                              <use xlinkHref="/img/icons.svg#icon-heart"></use>
                            </svg>
                          </button> 
                           */}
                        </div>
                      </div>
                    </div>
                ))}
            </div>
            {pages.length > 1 && (
            <ul className="pagination custom-pagination justify-content-center mt-4">
                {prevLink && (
                    <li className="page-item">
                        <a className="page-link" href={prevLink}>Prev</a>
                    </li>
                )}
                {pages.map((page, idx) => (
                <li key={`paging-${idx}`} className="page-item active"><a className="page-link" href={page.link}>{page.pageNumber}</a></li>
                ))}
                {nextLink && (
                    <li className="page-item">
                        <a className="page-link" href={nextLink}>Next</a>
                    </li>
                )}
            </ul>
            )}
      </div>
    )
}

export default BlogItems
    